import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent, Button, Icon, Link } from '@mui/material'
import { TextField, MenuSelectorField, SingleOptionSelectorField, ModelMenuSelectorField, ConfirmationDialog } from '@front/squirtle'

import { isRequired } from '@front/squirtle/utils/validators'
import FormatUtils from '@front/squirtle/utils/format'

import SuspensionDialog from '@abra/elements/Popup/Suspension'

const Row1 = ({ supported_languages, isManagingRecord, statusChange, color, handleClientNumberChange }) =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs={1}>
      <TextField
        name='customerinfo.client_number'
        label={I18n.t('user.id')}
        debounceTime={250}
        afterUserChange={handleClientNumberChange}
        required={!!isManagingRecord}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'auth.gsm'}
        label={I18n.t('phone.label', { count: 1 })}
        emptyValue='-'
        InputProps={{
          startAdornment: <Icon fontSize='small'> {'phone'} </Icon>
        }}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'auth.email'}
        label={I18n.t('email.label', { count: 1 })}
        InputProps={{
          startAdornment: <Icon fontSize='small'> {'alternate_email'} </Icon>
        }}
        emptyValue={'-'}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'commercial_formula'}
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name={'commercialformula'}
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        config={{ sortKeys: ['display_index'], sortOrders: ['asc'] }}
        required
        getOptionLabel={(option, type) => option?.commercial_formula_id && type === 'value'
          ? <Link href={`/commercialformula/${option?.commercial_formula_id}`} target={'_blank'}>
            {option?.name}
          </Link>
          : option?.name
        }
      />
    </Grid>
    <Grid container item xs={1}>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: <Box sx={{ color: 'green', fontWeight: 500 }}> {I18n.t('status.active')}  </Box>, value: 'active' },
          { label: <Box sx={{ color: 'red', fontWeight: 500 }}> {I18n.t('status.deleted')}  </Box>, value: 'deleted', disabled: !isManagingRecord },
          { label: <Box sx={{ color: 'orange', fontWeight: 500 }}> {I18n.t('status.suspended')}  </Box>, value: 'suspended', disabled: !isManagingRecord },
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: color, fontWeight: 600 }
        }}
        selectFirst
        validate={isRequired}
        afterChange={statusChange}
        required
      />
    </Grid>
    <Grid item xs={1}>
      <ModelMenuSelectorField
        name={'auth.application'}
        label={I18n.t('application.label', { count: 1 })}
        model_name={'application'}
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        selectFirst
        required
        config={{ sort: ['display_index ASC'], initial_filter: {} }}
      />
    </Grid>
    <Grid item xs={1}>
      <MenuSelectorField
        name={'info.preferred_language'}
        label={I18n.t('lang', { count: 1 })}
        options={_.map(supported_languages, (lang, index) => ({
          label: <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ mr: 1 }} > {FormatUtils.getFlagIcon(lang)} </Box>
            <Box> {_.capitalize(lang)} </Box>
          </Box>,
          value: lang
        }))}
        selectFirst
        required
      />
    </Grid>
  </Grid >

const Row2 = ({ isManagingRecord, namesRequired, designationRequired }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name={'customerinfo.name'}
        label={I18n.t('designation')}
        emptyValue='-'
        required={designationRequired}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'info.last_name'}
        label={I18n.t('name.label', { count: 1 })}
        emptyValue='-'
        required={namesRequired}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'info.first_name'}
        label={I18n.t('name.first_name', { count: 1 })}
        emptyValue='-'
        required={namesRequired}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        name={'customerinfo.email'}
        label={I18n.t('email.label.notification')}
        InputProps={{
          startAdornment: <Icon fontSize='small'> {'alternate_email'} </Icon>
        }}
        emptyValue='-'
      />
    </Grid>
    <Grid item xs hidden={!!isManagingRecord}>
      <SingleOptionSelectorField
        name={'welcome_email'}
        label={I18n.t('email.welcome.label', { count: 1 })}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'auth.gsm_verified'}
        label={I18n.t('phone.gsm_verified')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>

const Row3 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'info.address'} label={I18n.t('address.administrativ')} />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name={'customerinfo.ivr_access'}
        label={I18n.t('ivr_access.label', { count: 1 })}
        selectFirst
        noneLabel={I18n.t('commercial_formula.label.short')}
        options={[
          { label: I18n.t('ivr_access.never'), value: 0 },
          { label: I18n.t('ivr_access.yes_if_order'), value: 1 },
          { label: I18n.t('ivr_access.yes_without_limit'), value: 2 },
        ]}
      />
    </Grid>
  </Grid>

const PrimarySection = (props) => {
  const {
    formattedSuspension,
    suspendedStateName,
    initialSuspendedValues,
    displayRemoveSuspend,
    onClickRemoveSuspend,
    onConfirmRemoveSuspend
  } = props || {}
  return <>
    <SuspensionDialog
      form_name='client'
      enable_motive={true}
      suspendedStateName={suspendedStateName}
      initialValues={initialSuspendedValues}
    />
    <ConfirmationDialog
      name={'remove_client_suspend'}
      onConfirm={onConfirmRemoveSuspend}
    />
    <StepLabel>
      <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant='h5'> {I18n.t('info.general')} </Typography>
          <Typography sx={{ ml: 1, mt: '5px', fontSize: 'subtitle2.fontSize', color: 'red' }} > {formattedSuspension}</Typography>
          {!!displayRemoveSuspend &&
            <Button size='small' variant='outlined' onClick={onClickRemoveSuspend}>
              {I18n.t('suspend.stop.suspend')}
            </Button>}
        </Box>
      </Box>
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
        <Grid container rowSpacing={2} >
          <Row1 {...props} />
          <Row2 {...props} />
          <Row3 />
        </Grid>
      </Box>
    </StepContent>
  </>
}

export default React.memo(PrimarySection)
